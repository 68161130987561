import { Component, OnInit } from '@angular/core';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../app.component';
import { Episode, Location } from '../interfaces';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrl: './location.component.scss',
})
export class LocationComponent implements OnInit {
  loadingPage: boolean = true;
  locationInfo: any;
  safeSrc!: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  async ngOnInit(): Promise<void> {
    await this.getActiveLocation();
    this.loadingPage = false;
  }

  async getActiveLocation() {
    const tempEpisodeData: Episode[] = [];
    (
      await getDocs(
        query(
          collection(db, 'episodes'),
          where('hidden', '==', false),
          orderBy('number', 'desc'),
          limit(1)
        )
      )
    ).forEach((doc) => {
      const data = { ...doc.data(), id: doc.id } as Episode;
      tempEpisodeData.push(data);
    });

    const locationDoc = await getDoc(
      doc(db, `locations/${tempEpisodeData[0].location}`)
    );
    this.locationInfo = {
      ...locationDoc.data(),
      id: locationDoc.id,
    } as Location;

    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.locationInfo.gMapUrl
    );
  }
}
