import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  getDocs,
  query,
  collection,
  orderBy,
  where,
  limit,
} from 'firebase/firestore';
import { Episode, News } from '../interfaces';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ViewNewsItemDialog } from './dialogs/view-news-item/view-news-item.component';
import { db } from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  loadingPage: boolean = true;
  currentEpisode?: Episode | null = null;
  previousEpisode?: Episode | null = null;
  currentEpisodeStartDate?: {
    month: string;
    day: string;
  };
  currentEpisodePrice?: string;
  latestNews: News[] = [];

  constructor(private router: Router, public dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    await this.getEpisodeInfo();
    await this.getNews();
    this.loadingPage = false;
  }

  async getEpisodeInfo() {
    (
      await getDocs(
        query(
          collection(db, 'episodes'),
          where('hidden', '==', false),
          orderBy('number', 'desc'),
          limit(1)
        )
      )
    ).forEach((doc) => {
      const data = { ...doc.data(), id: doc.id } as Episode;
      this.currentEpisode = data;
    });

    this.currentEpisodeStartDate = {
      month: moment(this.currentEpisode?.startDate.toDate())
        .locale('nl')
        .format('MMMM')
        .substring(0, 3)
        .toUpperCase(),
      day: moment(this.currentEpisode?.startDate.toDate()).day().toString(),
    };
    this.currentEpisodePrice = this.currentEpisode?.ticketPrice
      .toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
      .replace(' €', '');

    (
      await getDocs(
        query(
          collection(db, 'episodes'),
          where('hidden', '==', false),
          where('photos', '==', true),
          orderBy('number', 'desc'),
          limit(1)
        )
      )
    ).forEach((doc) => {
      const data = { ...doc.data(), id: doc.id } as Episode;
      this.previousEpisode = data;
    });
  }

  async getNews() {
    (
      await getDocs(
        query(collection(db, 'news'), limit(5), orderBy('date', 'desc'))
      )
    ).forEach((doc) => {
      const docData = { ...doc.data(), id: doc.id } as News;
      docData['date'] = moment(docData['date'].toDate())
        .locale('nl')
        .format('DD-MM-YYYY');
      this.latestNews.push(docData);
    });
  }

  headForLocation(location: string, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.router.navigateByUrl(location);
  }

  openNewsDialog(newsItem?: News) {
    this.dialog.open(ViewNewsItemDialog, {
      width: '600px',
      data: { newsItem: newsItem },
      autoFocus: false,
    });
  }
}
