import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { News } from 'src/app/interfaces';

export interface DialogData {
  newsItem: News;
}

@Component({
  selector: 'app-view-news-item',
  templateUrl: './view-news-item.component.html',
  styleUrls: ['./view-news-item.component.scss'],
})
export class ViewNewsItemDialog implements OnInit {
  newsItem = this.data.newsItem;

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ViewNewsItemDialog>
  ) {}

  async ngOnInit() {}
}
