<mat-sidenav-container [hasBackdrop]="hasBackdrop" *ngIf="!loading">
  <mat-sidenav #drawer [mode]="mode" [(opened)]="open">
    <div class="side-nav-wrapper">
      <div class="side-nav-top">
        <button *ngIf="showButton" (click)="drawer.toggle()" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="side-nav-menu-items">
        <div *ngFor="let menuItem of menuItems; let i = index">
          <button
            mat-button
            class="side-nav-menu-item"
            *ngIf="
              !menuItem.hidden && !menuItem.dropdown && menuItem.routerLink
            "
            [routerLink]="menuItem.routerLink"
            [class.active]="menuItem.routerLink === currentRoute"
          >
            <mat-icon *ngIf="!menuItem.svg && menuItem.icon">
              {{ menuItem.icon }}
            </mat-icon>
            <mat-icon
              *ngIf="menuItem.svg && menuItem.icon"
              svgIcon="{{ menuItem.icon }}"
              class="svg-current-color"
            ></mat-icon>
            <span>{{ menuItem.name }}</span>
          </button>
          <button
            mat-button
            class="side-nav-menu-item"
            *ngIf="!menuItem.hidden && !menuItem.dropdown && menuItem.click"
            (click)="menuItem.click()"
            [class.active]="menuItem.routerLink === currentRoute"
          >
            <mat-icon *ngIf="!menuItem.svg && menuItem.icon">
              {{ menuItem.icon }}
            </mat-icon>
            <mat-icon
              *ngIf="menuItem.svg && menuItem.icon"
              svgIcon="{{ menuItem.icon }}"
              class="svg-current-color"
            ></mat-icon>
            <span>{{ menuItem.name }}</span>
          </button>
          <span
            *ngIf="
              !menuItem.hidden &&
              menuItem.dropdown &&
              menuItem.dropdown.length > 0
            "
          >
            <button
              class="side-nav-menu-item"
              color="accent"
              mat-button
              (click)="
                menuItems[i].collapsed === 'in'
                  ? (menuItems[i].collapsed = 'out')
                  : (menuItems[i].collapsed = 'in')
              "
            >
              <span class="side-nav-menu-item-expansion">
                {{ menuItem.name }}
                <mat-icon>{{
                  menuItem.collapsed === "in"
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_up"
                }}</mat-icon>
              </span>
            </button>
            <div class="side-nav-menu-dropdown" [@slide]="menuItem.collapsed">
              <div
                class="side-nav-dropdown-button-wrapper"
                *ngFor="let dropdownButton of menuItem.dropdown"
              >
                <button
                  mat-button
                  class="side-nav-dropdown-button"
                  *ngIf="dropdownButton.routerLink"
                  [routerLink]="dropdownButton.routerLink"
                  [class.active]="dropdownButton.routerLink === currentRoute"
                >
                  <mat-icon *ngIf="!dropdownButton.svg && dropdownButton.icon">
                    {{ dropdownButton.icon }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="dropdownButton.svg && dropdownButton.icon"
                    svgIcon="{{ dropdownButton.icon }}"
                    class="svg-current-color"
                  ></mat-icon>
                  <span>{{ dropdownButton.name }}</span>
                </button>
                <button
                  mat-button
                  class="side-nav-dropdown-button"
                  *ngIf="dropdownButton.click"
                  (click)="dropdownButton.click()"
                  [class.active]="dropdownButton.routerLink === currentRoute"
                >
                  <mat-icon *ngIf="!dropdownButton.svg && dropdownButton.icon">
                    {{ dropdownButton.icon }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="dropdownButton.svg && dropdownButton.icon"
                    svgIcon="{{ dropdownButton.icon }}"
                    class="svg-current-color"
                  ></mat-icon>
                  <span>{{ dropdownButton.name }}</span>
                </button>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="nav-bar" [ngClass]="{ sticky: mode === 'side' }">
      <div class="nav-bar-menu-title">
        <button *ngIf="showButton" (click)="drawer.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <div class="nav-bar-logo-title clickable unselectable" routerLink="/">
          <div class="logo unselectable"></div>
          <div class="title nav-bar-title unselectable">Full Duplex Lan</div>
        </div>
      </div>
      <div class="menu-top">
        <div *ngFor="let menuItem of menuItems; let i = index">
          <button
            mat-button
            class="menu-item"
            *ngIf="
              !menuItem.hidden && !menuItem.dropdown && menuItem.routerLink
            "
            [routerLink]="menuItem.routerLink"
            [class.active]="menuItem.routerLink === currentRoute"
          >
            <mat-icon *ngIf="!menuItem.svg && menuItem.icon">
              {{ menuItem.icon }}
            </mat-icon>
            <mat-icon
              *ngIf="menuItem.svg && menuItem.icon"
              svgIcon="{{ menuItem.icon }}"
              class="svg-current-color"
            ></mat-icon>
            <span>{{ menuItem.name }}</span>
          </button>
          <button
            mat-button
            class="menu-item"
            *ngIf="!menuItem.hidden && !menuItem.dropdown && menuItem.click"
            (click)="menuItem.click()"
            [class.active]="menuItem.routerLink === currentRoute"
          >
            <mat-icon *ngIf="!menuItem.svg && menuItem.icon">
              {{ menuItem.icon }}
            </mat-icon>
            <mat-icon
              *ngIf="menuItem.svg && menuItem.icon"
              svgIcon="{{ menuItem.icon }}"
              class="svg-current-color"
            ></mat-icon>
            <span>{{ menuItem.name }}</span>
          </button>
          <span
            *ngIf="
              !menuItem.hidden &&
              menuItem.dropdown &&
              menuItem.dropdown.length > 0
            "
          >
            <button
              class="menu-item"
              color="accent"
              mat-button
              [matMenuTriggerFor]="menu"
            >
              {{ menuItem.name }}
              <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div *ngFor="let dropdownButton of menuItem.dropdown">
                <button
                  mat-button
                  class="dropdown-button"
                  *ngIf="dropdownButton.routerLink"
                  [routerLink]="dropdownButton.routerLink"
                  [class.active]="dropdownButton.routerLink === currentRoute"
                >
                  <mat-icon *ngIf="!dropdownButton.svg && dropdownButton.icon">
                    {{ dropdownButton.icon }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="dropdownButton.svg && dropdownButton.icon"
                    svgIcon="{{ dropdownButton.icon }}"
                    class="svg-current-color"
                  ></mat-icon>
                  <span>{{ dropdownButton.name }}</span>
                </button>
                <button
                  mat-button
                  class="dropdown-button"
                  *ngIf="dropdownButton.click"
                  (click)="dropdownButton.click()"
                  [class.active]="dropdownButton.routerLink === currentRoute"
                >
                  <mat-icon *ngIf="!dropdownButton.svg && dropdownButton.icon">
                    {{ dropdownButton.icon }}
                  </mat-icon>
                  <mat-icon
                    *ngIf="dropdownButton.svg && dropdownButton.icon"
                    svgIcon="{{ dropdownButton.icon }}"
                    class="svg-current-color"
                  ></mat-icon>
                  <span>{{ dropdownButton.name }}</span>
                </button>
              </div>
            </mat-menu>
          </span>
        </div>
      </div>
    </div>
    <div class="app-body"><router-outlet></router-outlet></div>
  </mat-sidenav-content>
</mat-sidenav-container>
