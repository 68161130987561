import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsor-panel',
  templateUrl: './sponsor-panel.component.html',
  styleUrl: './sponsor-panel.component.scss',
})
export class SponsorPanelComponent implements OnInit {
  loadingPage: boolean = true;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.loadingPage = false;
  }
}
