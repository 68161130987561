<div
  class="news-dialog-image unselectable"
  style="
    background-image: url('{{ newsItem.image }}');
    "
></div>
<h2 mat-dialog-title class="header">{{ newsItem.title }}</h2>
<mat-dialog-content class="news-dialog-content">
  {{ newsItem.message }}
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="accent" mat-dialog-close>Sluiten</button>
</mat-dialog-actions>
