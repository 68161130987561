export const environment = {
  production: false,
  defaultRoute: {
    id: 'home',
    path: 'home',
  },
  firebase: {
    apiKey: 'AIzaSyCtEsZ3pz43L9Yk8f3y7OLJ1C0EFrtZgN8',
    authDomain: 'fullduplex-aecd3.firebaseapp.com',
    databaseURL:
      'https://fullduplex-aecd3-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'fullduplex-aecd3',
    storageBucket: 'fullduplex-aecd3.appspot.com',
    messagingSenderId: '613140274659',
    appId: '1:613140274659:web:7411c179ca3b55faef94d6',
  },
};
