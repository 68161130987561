import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { RulesComponent } from './rules/rules.component';
import { LocationComponent } from './location/location.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { CompetitionPanelComponent } from './competition-panel/competition-panel.component';
import { SponsorPanelComponent } from './sponsor-panel/sponsor-panel.component';
import { ProfileComponent } from './profile/profile.component';
import { EpisodeComponent } from './episode/episode.component';
import { PhotosComponent } from './photos/photos.component';

// const redirectUnauthorizedToLogin = () =>
//   redirectUnauthorizedTo([environment.defaultRoute.id]);
// const redirectLoggedInToDefault = () =>
//   redirectLoggedInTo([environment.defaultRoute.id]);

const routes: Routes = [];
const allRoutes = new Map<string, any>([
  //TODO Add actual protection here
  // [
  //   'home',
  //   {
  //     path: 'home',
  //     component: HomeComponent,
  //     canActivate: [AngularFireAuthGuard],
  //     data: { authGuardPipe: redirectLoggedInToDefault }
  //   }
  // ],
  [
    'home',
    {
      path: 'home',
      component: HomeComponent,
    },
  ],
  [
    'rules',
    {
      path: 'rules',
      component: RulesComponent,
    },
  ],
  [
    'location',
    {
      path: 'location',
      component: LocationComponent,
    },
  ],
  [
    'episode',
    {
      path: 'episode',
      component: EpisodeComponent,
    },
  ],
  [
    'photos',
    {
      path: 'photos',
      component: PhotosComponent,
    },
  ],
  [
    'profile',
    {
      path: 'profile',
      component: ProfileComponent,
    },
  ],
  [
    'apanel',
    {
      path: 'apanel',
      component: AdminPanelComponent,
    },
  ],
  [
    'cpanel',
    {
      path: 'cpanel',
      component: CompetitionPanelComponent,
    },
  ],
  [
    'spanel',
    {
      path: 'spanel',
      component: SponsorPanelComponent,
    },
  ],
]);

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router) {
    const config = router.config;
    allRoutes.forEach((route) => {
      config.push(route);
    });
    config.push({
      path: '**',
      redirectTo: environment.defaultRoute.path,
      // data: { authGuardPipe: redirectUnauthorizedToLogin },
    });
    router.resetConfig(config);
  }
}
