<div class="location-body" *ngIf="!loadingPage">
  <div class="location-wrapper">
    <div class="location-box">
      <div class="location-box-title title-small unselectable">Locatie</div>
    </div>
    <div class="location-box-map">
      <iframe
        width="100%"
        height="400px"
        style="border: 0"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        [src]="safeSrc"
      >
      </iframe>
    </div>
    <div class="location-boxes-middle">
      <div class="box-left">
        <div class="location-box">
          <div class="location-box-title header unselectable">
            {{ locationInfo.locationAddress }}
          </div>
        </div>
        <div class="location-box">
          <div class="location-box-title header unselectable">
            Locatie regels
          </div>
          <div class="location-box-content">
            <ul>
              <li *ngFor="let item of locationInfo.locationRules">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="box-right">
        <div class="location-box">
          <div class="location-box-title header unselectable">Douches</div>
          <div class="location-box-content">
            <div class="location-box-content-text">
              {{ locationInfo.showers ? "Beschikbaar" : "Niet beschikbaar" }}
            </div>
          </div>
        </div>
        <div class="location-box">
          <div class="location-box-title header unselectable">Bar</div>
          <div class="location-box-content">
            <ul>
              <li *ngFor="let item of locationInfo.bar">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-loader" *ngIf="loadingPage">
  <mat-spinner></mat-spinner>
</div>
