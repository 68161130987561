import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-competition-panel',
  templateUrl: './competition-panel.component.html',
  styleUrl: './competition-panel.component.scss',
})
export class CompetitionPanelComponent implements OnInit {
  loadingPage: boolean = true;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.loadingPage = false;
  }
}
