<div class="rules-wrapper" *ngIf="!loadingPage">
  <div class="rules-box-wrapper">
    <div class="rules-title">
      <div class="title-small">Huisregels</div>
      <div>Laatste update: 07-07-2024</div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('allowedToBring', $event)"
      >
        <div class="header unselectable">Toegestaan</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('allowedToBring', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.allowedToBring === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.allowedToBring">
        <ul>
          <li>Computer, laptop of console</li>
          <li>Muis, toetsenbord en muismat</li>
          <li>Beeldscherm</li>
          <li>Koptelefoon/headset</li>
          <li>Stekkerdoos</li>
          <li>Slaapspullen</li>
          <li>Opfris kit (tandenborstel, kleren, deo etc..)</li>
          <li>Zakcentje om wat lekkers te halen</li>
          <li>Legitimatie</li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('notAllowedToBring', $event)"
      >
        <div class="header unselectable">Verboden</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('notAllowedToBring', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.notAllowedToBring === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.notAllowedToBring">
        <ul>
          <li>
            Drugs (soft en harddrugs zijn verboden tijdens het hele evenement)
          </li>
          <li>Wapens (van elk soort)</li>
          <li>Grote hoeveelheden eten/drinken</li>
          <li>
            Zwaar elektrisch apparatuur (waterkoker, koffiezetapparaat, koelbox
            etc..)
          </li>
          <li>
            Geluidsboxen of ander storend materiaal (grote lampen, lasers etc…)
          </li>
          <li>Te grote televisies of beamers of andere grote objecten</li>
          <li>Glaswerk is verboden in de evenementzaal / gymzaal</li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('forSafety', $event)"
      >
        <div class="header unselectable">Veiligheid</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('forSafety', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.forSafety === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.forSafety">
        <div class="rules-box-content-text">
          De organisatie zal tijdens het gehele evenement surveillance rondes
          maken in en om de locatie, dit is om de veiligheid en het toezicht zo
          goed mogelijk te bewaken
        </div>
        <ul>
          <li>Het is op welke wijze verboden deze rondes te verhinderen</li>
          <li>
            Het evenement mag bezocht worden door mensen die geen tafel hebben,
            wel even aan- en afmelden bij betreffende crew in de zaal
          </li>
          <li>
            De organisatie heeft ten allen tijde het recht om de bezoeker te
            vragen naar legitimatie, als legitimatie niet getoond kan worden,
            behoudt de organisatie het recht om de bezoeker van het evenement te
            verwijderen
          </li>
          <li>
            Bezoekers zijn welkom van 10:00 t/m 22:00, buiten deze tijden worden
            bezoekers verzocht het terrein te verlaten
          </li>
          <li>
            Het is verboden om aan andermans apparatuur te komen zonder
            toestemming
          </li>
          <li>
            De crew is niet aansprakelijk voor verlies of schade aan eigendommen
          </li>
          <li>
            De organisatie is geheel niet verantwoordelijk voor eventuele
            aangerichte schade of de eventuele schade die wordt aangericht
          </li>
          <li>
            Wordt er toch schade of diefstal verricht, dan wordt deze verhaald
            op de betreffende personen
          </li>
          <li>
            Fijn glaswerk is niet toegestaan op het evenement (zoals
            cristalglas, bierglazen en ander fijn glaswerk)
          </li>
          <li>Het gehele Fullduplexlan evenement is drugsvrij</li>
          <li>
            Mocht er iemand toch drugs of wapens bij zich hebben, dan zal dat
            leiden tot verwijderen van het evenement
          </li>
          <li>
            Bij toch meenemen van niet toegestane spullen hebben wij het recht
            om het in te nemen tot het einde van het evenement, Hierbij kan ook
            de politie worden ingeschakeld
          </li>
          <li>
            Bij de slaapzaal is het verboden om met vloeistoffen en schoenen
            naar binnen te gaan (dit ivm zachte vloer)
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('foodDrink', $event)"
      >
        <div class="header unselectable">Eten/Drinken</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('foodDrink', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.foodDrink === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.foodDrink">
        <ul>
          <li>Het is toegestaan om alcohol te drinken, doe dit wel met mate</li>
          <li>
            Minderjarige mogen zoals de wetgeving beschrijft, geen alcohol
            consumeren
          </li>
          <li>Eten is alleen voor eigen gebruik, niet voor doorverkoop</li>
          <li>
            Eten bestellen mag, alleen mag er geen koerier aan de deur komen
          </li>
          <li>
            Het is toegestaan eten zelf te halen, 2 frietzaken en een supermarkt
            zitten op 300 meter loopafstand
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('competitions', $event)"
      >
        <div class="header unselectable">Wedstrijden</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('competitions', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.competitions === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.competitions">
        <ul>
          <li>
            De organisatie organiseert kleine wedstrijden, hiermee zijn kleine
            prijsjes mee te verdienen
          </li>
          <li>
            Tijdens de competities is het verboden om gebruik te maken van
            hacks, cheats of trainers. Mocht dat toch gebeuren, dan wordt die
            speler gediskwalificeerd
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('lan', $event)"
      >
        <div class="header unselectable">Netwerk</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('lan', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.lan === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.lan">
        <ul>
          <li>
            Op elke tafel van de deelnemer zal informatie staan over de gegevens
            van het netwerk (plaatsnummer en deelnemer naam)
          </li>
          <li>
            Elke plaats heeft 1 netwerk kabel - Routers/switches etc. zijn
            verboden om aan te sluiten
          </li>
          <li>
            Hacken is ten strengste verboden en zal leiden tot onmiddellijke
            verwijdering van het evenement
          </li>
          <li>
            Het netwerk express belemmeren is ten strengste verboden en zal
            leiden tot onmiddellijke verwijdering van het evenement
          </li>
          <li>Mocht je vragen hebben, dan kun je altijd bij de crew terecht</li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('electricity', $event)"
      >
        <div class="header unselectable">Stroomvoorziening</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('electricity', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.electricity === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.electricity">
        <ul>
          <li>Elke deelnemer heeft 1 stopcontact</li>
          <li>
            Daarop mag de deelnemer 1 verlengdoos aansluiten (2 of meer zijn
            verboden)
          </li>
          <li>Grote stroomverbruikende apparaten zijn verboden</li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('terrain', $event)"
      >
        <div class="header unselectable">Terrein</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('terrain', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.terrain === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.terrain">
        <ul>
          <li>
            Het opgegeven terrein mag alleen via de hoofdingang betreden worden
          </li>
          <li>
            Ook is er een 22:00 tot 8:00 mentaliteit, houdt zoveel mogelijk
            stilte ook i.v.m. omwonenden
          </li>
          <li>
            Roken is alleen toegestaan op de betreffende aangewezen locaties bij
            de hoofdingang (ook 's avonds en 's nachts)
          </li>
          <li>Nooddeuren zijn alleen in geval van nood te gebruiken</li>
          <li>
            Rommel kan weggegooid worden in de aanwezige afvalzakken, mocht er 1
            vol zijn, geef het even aan bij de betreffende crew
          </li>
          <li>
            Wij scheiden afval, gooi de betreffende rommel in de toegewezen
            afvalzakken
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('entry', $event)"
      >
        <div class="header unselectable">Entree</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('entry', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.entry === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.entry">
        <ul>
          <li>Rond 18:00 op vrijdag avond zullen de deuren opengaan</li>
          <li>
            Bij de entree wordt er een polsbandje afgegeven nadat het
            toegangsbewijs is verguld
          </li>
          <li>
            Dit toegangsbandje van Fullduplexlan moet het gehele evenement
            zichtbaar zijn
          </li>
          <li>
            Mocht een deelnemer deze kwijt raken, dan dient deze deelnemer zich
            te melden bij de organisatie, voor een nieuw polsbandje
          </li>
          <li>
            De organisatie heeft het recht om personen zonder toegangsbewijs van
            het evenement te verwijderen
          </li>
          <li>
            In het geval van een minderjarige is toestemming van een van de
            ouders vereist
          </li>
          <li>
            Bij het registreren op de website worden NAW-gegevens gevraagd, deze
            worden opgeslagen en indien nodig opgevraagd uit onze database (in
            geval van ziekte/allergieën of incidenten)
          </li>
          <li>Gegevens opvragen is alleen mogelijk door crew en/of toezicht</li>
          <li>
            Een minderjarige dient bij aanvang van het evenement, een ouder of
            voogd mee te nemen
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('calamities', $event)"
      >
        <div class="header unselectable">Calamiteiten</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('calamities', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.calamities === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.calamities">
        <ul>
          <li>
            De organisatie van Fullduplexlan zal te allen tijde preventief te
            werk gaan
          </li>
          <li>Er is een calamiteitenplan en een EHBO-er aanwezig</li>
          <li>
            De nooduitgangen zijn duidelijk aangegeven. Mocht er een calamiteit
            ontstaan, volg de instructies van de organisatie op
          </li>
          <li>
            Iedereen dient zich dan te verzamelen op de parkeerplaats achter de
            sporthal
          </li>
        </ul>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('software', $event)"
      >
        <div class="header unselectable">Softwaren</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('software', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.software === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.software">
        <div class="rules-box-content-text">
          Het is ten strengste verboden om illegale en/of auteursrechtelijk
          beschermde software en/of data te verspreiden. Alle programmatuur,
          bestanden en/of data die door onze bezoekers via het netwerk worden
          getransporteerd, wordt geacht freeware, shareware of public domain te
          zijn. Wanneer de Fullduplexlan crew van het tegendeel op de hoogte
          wordt gesteld, wordt de melding nader onderzocht en de overtreder
          gewaarschuwd en/of verwijderd van het evenement. Uit privacy
          overwegingen is de organisatie niet in staat om een permanente
          controle uit te voeren op de data die over het netwerk wordt
          getransporteerd. Derhalve is de organisatie van het evenement niet
          aansprakelijk. De organisatie van Fullduplexlan aanvaardt geen
          verantwoordelijkheid voor inhoud, gebruik en eigendom
        </div>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('incidents', $event)"
      >
        <div class="header unselectable">Incidenten</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('incidents', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.incidents === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.incidents">
        <div class="rules-box-content-text">
          Mocht een deelnemer/bezoeker verwondingen oplopen, spreek dan direct
          een iemand van de organisatie aan, hij/zij zal de desbetreffende
          persoon kunnen behandelen. Wanneer de crew niet kan helpen bij de
          verwonding, dan zal gelijk het alarmnummer (09008844/112) gebeld
          worden. De crew is niet aansprakelijk in geval van letsel. In geval
          van een vechtpartij of dreiging tussen twee of meer personen zullen de
          betrokken personen tot de orde geroepen worden, daarbij krijgen de
          beide partijen een waarschuwing. Bij de 2de waarschuwing zal/zullen de
          betreffende deelnemer(s) verzocht worden om het terrein en evenement
          direct te verlaten. Mocht hij/zij het terrein niet willen verlaten,
          dan zal hij/zij worden overgedragen aan de politie. Fullduplexlan
          heeft ook het recht deze deelnemers in komende evenementen te weigeren
        </div>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('screenMaterial', $event)"
      >
        <div class="header unselectable">Beeldmateriaal</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('screenMaterial', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.screenMaterial === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.screenMaterial">
        <div class="rules-box-content-text">
          We attenderen de deelnemers/bezoekers erop dat er beeld- en
          geluidsopnames kunnen worden gemaakt voor reclame, website en
          aftermovie doeleinden. Mocht hier bezwaar tegen zijn, dan kan dit
          worden gemeld bij de crew of toezicht op het evenement
        </div>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('users', $event)"
      >
        <div class="header unselectable">Gebruikers</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('users', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.users === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.users">
        <div class="rules-box-content-text">
          Bij het creëren van een Gebruikersaccount, vragen wij u om de
          formulieren naar waarheid in te vullen. Dit is om de veiligheid van
          iedereen te waarborgen tijdens het evenement. Word deze niet naar
          waarheid ingevuld, dan behouden wij het recht om de Gebruiker te
          verwijderen of om deze af te schermen.
        </div>
      </div>
    </div>
    <div class="rules-box">
      <div
        class="rules-box-title unselectable"
        (click)="collapse('cancel', $event)"
      >
        <div class="header unselectable">Annuleren</div>
        <button
          mat-icon-button
          class="unselectable"
          (click)="collapse('cancel', $event)"
        >
          <mat-icon class="unselectable">{{
            collapsibles.cancel === "in"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"
          }}</mat-icon>
        </button>
      </div>
      <div class="rules-box-content" [@slide]="collapsibles.cancel">
        <div class="rules-box-content-text">
          Mocht het zo zijn dat je niet in staat bent om naar het evenement te
          komen, dan kan je jezelf uitschrijven door middel van een email te
          sturen naar
          <a href="mailto:info@fullduplex.nl">{{ "info@fullduplex.nl" }}</a> Als
          je dit voor 14 dagen, voor de aanvang van het evenement doet, kom je
          in aanmerking voor een terug betaling
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-loader" *ngIf="loadingPage">
  <mat-spinner></mat-spinner>
</div>
