// Core Modules
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl);

// Angular Material Modules
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

//Component Modules
import { HomeComponent } from './home/home.component';
import { ViewNewsItemDialog } from './home/dialogs/view-news-item/view-news-item.component';
import { LoginDialog } from './dialogs/login-dialog/login-dialog.component';
import { CustomDateAdapter } from './custom-date-adapter';
import { RulesComponent } from './rules/rules.component';
import { LocationComponent } from './location/location.component';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { CompetitionPanelComponent } from './competition-panel/competition-panel.component';
import { SponsorPanelComponent } from './sponsor-panel/sponsor-panel.component';
import { ProfileComponent } from './profile/profile.component';
import { EpisodeComponent } from './episode/episode.component';
import { PhotosComponent } from './photos/photos.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill',
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RulesComponent,
    LocationComponent,
    ViewNewsItemDialog,
    LoginDialog,
    AdminPanelComponent,
    CompetitionPanelComponent,
    SponsorPanelComponent,
    ProfileComponent,
    EpisodeComponent,
    PhotosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatListModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
