import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrl: './rules.component.scss',
  animations: [
    trigger('slide', [
      state(
        'in',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class RulesComponent implements OnInit {
  loadingPage: boolean = true;
  collapsibles: any = {
    allowedToBring: 'in',
    notAllowedToBring: 'in',
    forSafety: 'in',
    foodDrink: 'in',
    competitions: 'in',
    lan: 'in',
    electricity: 'in',
    terrain: 'in',
    entry: 'in',
    calamities: 'in',
    software: 'in',
    incidents: 'in',
    screenMaterial: 'in',
    users: 'in',
    cancel: 'in',
  };

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.loadingPage = false;
  }

  collapse(type: string, event: any) {
    event.stopPropagation();
    this.collapsibles[type] = this.collapsibles[type] === 'out' ? 'in' : 'out';
  }
}
