<h2 mat-dialog-title class="header unselectable">
  {{
    dialogForm === "login"
      ? "Inloggen"
      : dialogForm === "forgotPass" || dialogForm === "forgotPassConfirmation"
      ? "Wachtwoord vergeten"
      : "Registreren"
  }}
</h2>
<mat-dialog-content
  *ngIf="dialogForm === 'login'"
  class="login-mat-dialog-content"
>
  <form class="login-form" (ngSubmit)="submitForm()" [formGroup]="loginForm">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        type="email"
        autocomplete="email"
        placeholder="john@doe.com"
        formControlName="email"
        (keydown.Enter)="submitForm()"
      />
      <mat-error>{{ errorCheckLogin("email") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Wachtwoord</mat-label>
      <input
        matInput
        type="{{ loginPasswordVis ? 'text' : 'password' }}"
        autocomplete="current-password"
        formControlName="password"
        (keydown.Enter)="submitForm()"
      />
      <button
        mat-icon-button
        type="button"
        matSuffix
        (click)="loginPasswordVis = !loginPasswordVis"
      >
        <mat-icon>{{
          loginPasswordVis ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
      <mat-error>{{ errorCheckLogin("password") }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-content
  *ngIf="dialogForm === 'forgotPass'"
  class="login-mat-dialog-content"
>
  <form
    class="login-form"
    (ngSubmit)="submitForm()"
    [formGroup]="forgotPassForm"
  >
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        type="email"
        autocomplete="email"
        placeholder="john@doe.com"
        formControlName="email"
        (keyup.Enter)="submitForm()"
      />
      <mat-error>{{ errorCheckForgotPass("email") }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-content
  *ngIf="dialogForm === 'register'"
  class="login-mat-dialog-content"
>
  <form class="login-form" (ngSubmit)="submitForm()" [formGroup]="registerForm">
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        type="email"
        autocomplete="email"
        placeholder="john@doe.com"
        formControlName="email"
      />
      <mat-error>{{ errorCheckRegister("email") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Wachtwoord</mat-label>
      <input
        matInput
        type="{{ registerPasswordVis ? 'text' : 'password' }}"
        autocomplete="new-password"
        formControlName="password"
        (keyup.Enter)="submitForm()"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="registerPasswordVis = !registerPasswordVis"
      >
        <mat-icon>{{
          registerPasswordVis ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
      <mat-error>{{ errorCheckRegister("password") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Bevestig wachtwoord</mat-label>
      <input
        matInput
        type="{{ registerPasswordVis ? 'text' : 'password' }}"
        autocomplete="new-password"
        formControlName="confirmPassword"
        (keyup.Enter)="submitForm()"
      />
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="registerPasswordVis = !registerPasswordVis"
      >
        <mat-icon>{{
          registerPasswordVis ? "visibility_off" : "visibility"
        }}</mat-icon>
      </button>
      <mat-error>{{ errorCheckRegister("confirmPassword") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Naam</mat-label>
      <input
        matInput
        type="text"
        placeholder="John Doe"
        autocomplete="name"
        formControlName="name"
      />
      <mat-error>{{ errorCheckRegister("name") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Gebruikersnaam</mat-label>
      <input
        matInput
        type="text"
        autocomplete="nickname"
        formControlName="username"
      />
      <mat-error>{{ errorCheckRegister("username") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Geboortedatum</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="DD-MM-YYYY"
        autocomplete="bday"
        formControlName="birthdate"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error>{{ errorCheckRegister("birthdate") }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" hideRequiredMarker="true">
      <mat-label>Telefoonnummer</mat-label>
      <input
        matInput
        type="tel"
        placeholder="0612345678"
        autocomplete="tel"
        formControlName="phoneNumber"
      />
      <mat-error>{{ errorCheckRegister("phoneNumber") }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-content
  *ngIf="dialogForm === 'forgotPassConfirmation'"
  class="login-mat-dialog-content"
>
  Als het ingevulde e-mailadres bekend is in het systeem, zal deze binnenkort
  een e-mail ontvangen.
</mat-dialog-content>
<mat-dialog-actions class="login-dialog-actions">
  <div class="login-dialog-wrapper">
    <div
      class="login-text-hover unselectable"
      *ngIf="dialogForm !== 'login' && dialogForm !== 'forgotPassConfirmation'"
      (click)="changeToForm('login')"
    >
      Inloggen?
    </div>
    <div
      class="login-text-hover unselectable"
      *ngIf="
        dialogForm !== 'forgotPass' &&
        dialogForm !== 'register' &&
        dialogForm !== 'forgotPassConfirmation'
      "
      (click)="changeToForm('forgotPass')"
    >
      Wachtwoord vergeten?
    </div>
    <div
      class="login-text-hover unselectable"
      *ngIf="
        dialogForm !== 'register' &&
        dialogForm !== 'forgotPass' &&
        dialogForm !== 'forgotPassConfirmation'
      "
      (click)="changeToForm('register')"
    >
      Nog geen account?
    </div>
    <div class="login-dialog-buttons">
      <button mat-flat-button color="accent" mat-dialog-close>
        {{ dialogForm !== "forgotPassConfirmation" ? "Annuleren" : "Sluiten" }}
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="dialogForm !== 'forgotPassConfirmation'"
        [disabled]="loading"
        (click)="submitForm()"
      >
        <mat-spinner
          class="button-loader"
          diameter="20"
          *ngIf="loading"
        ></mat-spinner>
        {{
          dialogForm === "login"
            ? "Inloggen"
            : dialogForm === "register"
            ? "Registeren"
            : "Verzenden"
        }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
