import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  getDocs,
  query,
  collection,
  orderBy,
  getDoc,
  doc,
  limit,
} from 'firebase/firestore';
import { MatMenuPanel } from '@angular/material/menu';
import { MenuItem, MenuItemDropdown, User } from './interfaces';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from './dialogs/login-dialog/login-dialog.component';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    trigger('slide', [
      state(
        'in',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class AppComponent implements OnInit {
  loading: boolean = true;
  hasBackdrop: boolean = true;
  mode: 'side' | 'over' = 'over';
  open: boolean = false;
  showButton: boolean = true;
  currentRoute: string = '';
  currentMenuItem?: MenuItem | MenuItemDropdown;
  icons: string[] = [];
  menuItems: MenuItemDropdown[] = [
    {
      name: 'Algemene informatie',
      routerLink: '',
      svg: false,
      hidden: false,
      collapsed: 'in',
      dropdown: [
        {
          name: 'Huisregels',
          routerLink: '/rules',
          svg: false,
          hidden: false,
        },
        {
          name: 'Locatie',
          routerLink: '/location',
          svg: false,
          hidden: false,
        },
      ],
    },
    {
      name: 'Episodes',
      routerLink: '',
      svg: false,
      hidden: false,
      collapsed: 'in',
      dropdown: [],
    },
    {
      name: "Foto's",
      routerLink: '',
      svg: false,
      hidden: false,
      collapsed: 'in',
      dropdown: [],
    },
    {
      name: 'Contact',
      routerLink: '/contact',
      svg: false,
      hidden: true,
    },
  ];
  userMenu: MatMenuPanel<any> | null = null;
  constructor(
    private router: Router,
    private location: Location,
    iconRegistry: MatIconRegistry,
    public dialog: MatDialog,
    sanitizer: DomSanitizer
  ) {
    router.events.subscribe(() => {
      const activeRouteWithoutParams = this.getActiveRouteWithoutParams();
      this.currentRoute = activeRouteWithoutParams;
      this.currentMenuItem = this.menuItems.find((menuItem) => {
        return menuItem.routerLink === this.currentRoute;
      });
      this.onWindowResize();
    });

    this.icons.forEach((icon) => {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
      );
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth < 701) {
      this.open = false;
      this.showButton = true;
    } else {
      this.open = false;
      this.showButton = false;
    }
  }

  async ngOnInit(): Promise<void> {
    this.onWindowResize();
    onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const menuItem = this.menuItems.find(
          (menuItem) => menuItem.name === 'Inloggen'
        );
        if (menuItem) {
          this.menuItems.splice(this.menuItems.indexOf(menuItem), 1);
        }
        const menuItemsObject: MenuItemDropdown = {
          name: 'Account',
          routerLink: '',
          svg: false,
          hidden: false,
          collapsed: 'in',
          dropdown: [
            {
              name: 'Profiel',
              routerLink: '/profile',
              svg: false,
              hidden: false,
            },
          ],
        };

        const userDoc = await getDoc(doc(db, `users/${user.uid}`));
        const userData = { ...userDoc.data(), id: userDoc.id } as User;

        const arrayToAdd = [];

        switch (userData.rights) {
          case 'admin':
            arrayToAdd.push({
              name: 'Admin paneel',
              routerLink: '/apanel',
              svg: false,
              hidden: false,
            });
            arrayToAdd.push({
              name: 'Compo paneel',
              routerLink: '/cpanel',
              svg: false,
              hidden: false,
            });
            arrayToAdd.push({
              name: 'Sponsor paneel',
              routerLink: '/spanel',
              svg: false,
              hidden: false,
            });
            break;
          case 'competitions':
            arrayToAdd.push({
              name: 'Compo paneel',
              routerLink: '/cpanel',
              svg: false,
              hidden: false,
            });
            break;
          case 'sponsor':
            arrayToAdd.push({
              name: 'Sponsor paneel',
              routerLink: '/spanel',
              svg: false,
              hidden: false,
            });
            break;
          case 'user':
            break;
        }

        arrayToAdd.push({
          name: 'Uitloggen',
          click: () => this.logout(),
          svg: false,
          hidden: false,
        });

        arrayToAdd.forEach((menuItem) => {
          menuItemsObject.dropdown?.push(menuItem);
        });

        this.menuItems.push(menuItemsObject);
      } else {
        const menuItem = this.menuItems.find(
          (menuItem) => menuItem.name === 'Account'
        );
        if (menuItem) {
          this.menuItems.splice(this.menuItems.indexOf(menuItem), 1);
        }
        this.menuItems.push({
          name: 'Inloggen',
          click: () => this.openLoginDialog(),
          svg: false,
          hidden: false,
        });
      }
    });
    //TODO rewrite this, needs to grab singular doc with array of episode objects containing: title, hidden, number, photos. Document needs to be maintained by DB trigger
    (
      await getDocs(
        query(collection(db, 'episodes'), orderBy('number', 'desc'))
      )
    ).forEach((doc) => {
      const data = doc.data();
      const pushObj: MenuItem = {
        name: data['title'],
        routerLink: `/episode?e=${data['number']}`,
        svg: false,
        hidden: data['hidden'],
      };

      const arrayIndex = this.menuItems.findIndex(
        (object) => object.name === 'Episodes'
      );

      this.menuItems[arrayIndex].dropdown?.push(pushObj);
    });

    this.loading = false;
  }

  getActiveRouteWithoutParams() {
    let currentUrl = this.location.path();
    currentUrl = currentUrl.substring(1, currentUrl.length);
    const paramsIndex = currentUrl.indexOf('/');
    if (paramsIndex != -1) {
      currentUrl = currentUrl.substring(0, paramsIndex);
    }
    currentUrl = `/${currentUrl}`; // Adds the / back in front
    return currentUrl;
  }

  headForLocation(location: string, event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.router.navigateByUrl(location);
  }

  openLoginDialog() {
    this.dialog.open(LoginDialog, {
      width: '600px',
      autoFocus: false,
    });
  }

  logout() {
    console.log('test');
    const auth = getAuth();
    signOut(auth)
      .then((user) => {
        console.log('logoutUser', user);
        location.reload();
      })
      .catch((error) => {
        console.log('test', error);
      });
  }
}
