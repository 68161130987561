import { Component, OnInit } from '@angular/core';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { doc, setDoc } from 'firebase/firestore';
import { db } from 'src/app/app.component';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialog implements OnInit {
  dialogForm: string = 'login';
  loginPasswordVis: boolean = false;
  registerPasswordVis: boolean = false;
  loading: boolean = false;
  loginForm = this.fb.group({
    email: [, [Validators.required, CustomValidators.emailValidator]],
    password: [, Validators.required],
  });
  forgotPassForm = this.fb.group({
    email: [, [Validators.required, CustomValidators.emailValidator]],
  });
  registerForm = this.fb.group({
    email: [, [Validators.required, CustomValidators.emailValidator]],
    password: [
      ,
      [
        Validators.required,
        CustomValidators.cannotContainSpace,
        CustomValidators.minimumPasswordRequirement,
      ],
    ],
    confirmPassword: [
      ,
      [
        Validators.required,
        CustomValidators.cannotContainSpace,
        CustomValidators.minimumPasswordRequirement,
      ],
    ],
    name: [, Validators.required],
    username: [, Validators.required],
    birthdate: [, Validators.required],
    phoneNumber: [, [Validators.required, CustomValidators.phoneValidator]],
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginDialog>,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit() {
    this.registerForm.controls['password'].valueChanges.subscribe(() => {
      if (
        this.registerForm.controls['password'].value !==
          this.registerForm.controls['confirmPassword'].value &&
        this.registerForm.controls['password'].value !== null &&
        this.registerForm.controls['confirmPassword'].value !== null
      ) {
        if (this.registerForm.controls['password'].errors === null) {
          this.registerForm.controls['password'].setErrors({
            comparePasswords: true,
          });
        } else {
          this.registerForm.controls['password'].errors['comparePasswords'] =
            true;
        }

        if (this.registerForm.controls['confirmPassword'].errors === null) {
          this.registerForm.controls['confirmPassword'].setErrors({
            comparePasswords: true,
          });
        } else {
          this.registerForm.controls['confirmPassword'].errors[
            'comparePasswords'
          ] = true;
        }
      } else {
        if (this.registerForm.controls['password'].errors) {
          delete this.registerForm.controls['password'].errors[
            'comparePasswords'
          ];
          if (
            Object.keys(this.registerForm.controls['password'].errors).length <
            1
          ) {
            this.registerForm.controls['password'].setErrors(null);
          }
        }
        if (this.registerForm.controls['confirmPassword'].errors) {
          delete this.registerForm.controls['confirmPassword'].errors[
            'comparePasswords'
          ];
          if (
            Object.keys(this.registerForm.controls['confirmPassword'].errors)
              .length < 1
          ) {
            this.registerForm.controls['confirmPassword'].setErrors(null);
          }
        }
      }
    });
    this.registerForm.controls['confirmPassword'].valueChanges.subscribe(() => {
      if (
        this.registerForm.controls['password'].value !==
          this.registerForm.controls['confirmPassword'].value &&
        this.registerForm.controls['password'].value !== null &&
        this.registerForm.controls['confirmPassword'].value !== null
      ) {
        if (this.registerForm.controls['password'].errors === null) {
          this.registerForm.controls['password'].setErrors({
            comparePasswords: true,
          });
        } else {
          this.registerForm.controls['password'].errors['comparePasswords'] =
            true;
        }

        if (this.registerForm.controls['confirmPassword'].errors === null) {
          this.registerForm.controls['confirmPassword'].setErrors({
            comparePasswords: true,
          });
        } else {
          this.registerForm.controls['confirmPassword'].errors[
            'comparePasswords'
          ] = true;
        }
      } else {
        if (this.registerForm.controls['password'].errors) {
          delete this.registerForm.controls['password'].errors[
            'comparePasswords'
          ];
          if (
            Object.keys(this.registerForm.controls['password'].errors).length <
            1
          ) {
            this.registerForm.controls['password'].setErrors(null);
          }
        }
        if (this.registerForm.controls['confirmPassword'].errors) {
          delete this.registerForm.controls['confirmPassword'].errors[
            'comparePasswords'
          ];
          if (
            Object.keys(this.registerForm.controls['confirmPassword'].errors)
              .length < 1
          ) {
            this.registerForm.controls['confirmPassword'].setErrors(null);
          }
        }
      }
    });
  }

  changeToForm(type: string) {
    this.dialogForm = type;
  }

  submitForm() {
    this.loading = true;
    switch (this.dialogForm) {
      case 'login':
        this.loginForm.markAllAsTouched();
        if (this.loginForm.valid) {
          signInWithEmailAndPassword(
            getAuth(),
            this.loginForm.controls['email'].value,
            this.loginForm.controls['password'].value
          )
            .then(() => {
              location.reload();
            })
            .catch((error) => {
              switch (error) {
                case 'auth/invalid-credential':
                  this.snackbar.open(
                    'De combinatie van e-mailadres en wachtwoord is niet geldig',
                    'X',
                    {
                      duration: 5000,
                    }
                  );
                  break;
                default:
                  this.snackbar.open(
                    'Er is iets fout gegaan, probeer het later opnieuw of contacteer ons.',
                    'X',
                    {
                      duration: 5000,
                    }
                  );
                  break;
              }
            });
        }
        this.loading = false;
        break;
      case 'forgotPass':
        this.forgotPassForm.markAllAsTouched();
        if (this.forgotPassForm.valid) {
          sendPasswordResetEmail(
            getAuth(),
            this.forgotPassForm.controls['email'].value
          )
            .then(() => {
              this.dialogForm = 'forgotPassConfirmation';
            })
            .catch((error) => {
              console.log('test-code', error.code);
              console.log('test-message', error.message);
            });
        }
        this.loading = false;
        break;
      case 'register':
        this.registerForm.markAllAsTouched();
        if (this.registerForm.valid) {
          createUserWithEmailAndPassword(
            getAuth(),
            this.registerForm.controls['email'].value,
            this.registerForm.controls['password'].value
          )
            .then(async (userCredential) => {
              //TODO make this a cloud function
              const saveObj = this.registerForm.value;
              saveObj['rights'] = 'user';
              delete saveObj['password'];
              delete saveObj['confirmPassword'];
              await setDoc(
                doc(db, `users/${userCredential.user.uid}`),
                saveObj
              );
              location.reload();
            })
            .catch((error) => {
              console.log('test-code', error.code);
              console.log('test-message', error.message);
            });
        }
        this.loading = false;
        break;
    }
  }

  errorCheckLogin(control: string) {
    let errorMessage;
    switch (control) {
      case 'email':
        if (this.loginForm.controls[control].hasError('required')) {
          errorMessage = 'Een waarde is vereist';
        }
        if (this.loginForm.controls['email'].hasError('emailValidator')) {
          errorMessage = 'Ingevulde waarde is incorrect';
        }
        break;
      case 'password':
        if (this.loginForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        break;
    }

    return errorMessage;
  }

  errorCheckForgotPass(control: string) {
    let errorMessage;
    switch (control) {
      case 'email':
        if (this.forgotPassForm.controls[control].hasError('required')) {
          errorMessage = 'Een waarde is vereist';
        }
        if (this.forgotPassForm.controls['email'].hasError('emailValidator')) {
          errorMessage = 'Ingevulde waarde is incorrect';
        }
        break;
    }

    return errorMessage;
  }

  errorCheckRegister(control: string) {
    let errorMessage;
    switch (control) {
      case 'email':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = 'Een waarde is vereist';
        }
        if (this.registerForm.controls['email'].hasError('emailValidator')) {
          errorMessage = 'Ingevulde waarde is incorrect';
        }
        break;
      case 'password':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        if (this.registerForm.controls[control].hasError('comparePasswords')) {
          errorMessage = errorMessage = 'Wachtwoorden komen niet overeen';
        }
        if (
          this.registerForm.controls[control].hasError(
            'minimumPasswordRequirement'
          )
        ) {
          errorMessage = errorMessage = 'Minimum van 6 karakters vereist';
        }
        if (
          this.registerForm.controls[control].hasError('cannotContainSpace')
        ) {
          errorMessage = errorMessage = 'Spaties zijn niet toegestaan';
        }
        break;
      case 'confirmPassword':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        if (this.registerForm.controls[control].hasError('comparePasswords')) {
          errorMessage = errorMessage = 'Wachtwoorden komen niet overeen';
        }
        if (
          this.registerForm.controls[control].hasError(
            'minimumPasswordRequirement'
          )
        ) {
          errorMessage = errorMessage = 'Minimum van 6 karakters vereist';
        }
        if (
          this.registerForm.controls[control].hasError('cannotContainSpace')
        ) {
          errorMessage = errorMessage = 'Spaties zijn niet toegestaan';
        }
        break;
      case 'name':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        break;
      case 'username':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        break;
      case 'birthdate':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        break;
      case 'phoneNumber':
        if (this.registerForm.controls[control].hasError('required')) {
          errorMessage = errorMessage = 'Een waarde is vereist';
        }
        if (this.registerForm.controls[control].hasError('phoneValidator')) {
          errorMessage = errorMessage = 'Ingevulde waarde is incorrect';
        }
        break;
    }

    return errorMessage;
  }
}
