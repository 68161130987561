<div class="home-body" *ngIf="!loadingPage">
  <div class="carousel">
    <div class="carousel-space unselectable"></div>
    <div
      class="carousel-image unselectable"
      (click)="headForLocation('/episode?e=' + currentEpisode?.number, $event)"
    >
      <img
        class="carousel-image-file unselectable"
        src="{{ currentEpisode?.image }}"
      />
      <div class="blur-box unselectable"></div>
    </div>
    <div class="carousel-space unselectable"></div>
  </div>

  <div class="info-boxes">
    <div
      class="info-box"
      (click)="headForLocation('/episode?e=' + currentEpisode?.number, $event)"
    >
      <div
        class="info-box-image"
        style="
          background-image: url('{{ currentEpisode?.image }}');
        "
      >
        <div class="info-box-image-date">
          <div class="info-box-image-date-month unselectable">
            {{ currentEpisodeStartDate?.month }}
          </div>
          <div class="info-box-image-date-day unselectable">
            {{ currentEpisodeStartDate?.day }}
          </div>
        </div>
      </div>
      <div class="info-box-content">
        <div class="info-box-title header">Aankomende episode</div>
        <div class="info-box-episode-name">{{ currentEpisode?.title }}</div>
        <div class="info-box-episode-location-price">
          <div
            class="info-box-location"
            (click)="headForLocation('/location', $event)"
          >
            <mat-icon>location_on</mat-icon
            ><span>{{ currentEpisode?.locationName }}</span>
          </div>
          <div class="info-box-price">
            {{ "€ " + currentEpisode?.ticketPrice + ",-" }}
          </div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <div
        class="info-box-image"
        style="
      background-image: url('{{ previousEpisode?.image }}');
    "
      ></div>
      <div class="info-box-content">
        <div class="info-box-title header">Foto's vorige episode</div>
        <div class="info-box-message photo-last">
          Bekijk de foto gallerij van de vorige episode om een impressie te
          krijgen
        </div>
      </div>
    </div>
    <div class="info-box" (click)="openNewsDialog(latestNews[0])">
      <div
        class="info-box-image"
        style="
        background-image: url('{{ latestNews[0].image }}');
      "
      ></div>
      <div class="info-box-content">
        <div class="info-box-title header">Laatste nieuws</div>
        <div class="info-box-message">
          {{ latestNews[0].message }}
        </div>
      </div>
    </div>
  </div>

  <div class="news-wrapper">
    <div class="news-title-box">
      <div class="news-box-title title-small">Nieuws</div>
    </div>
    <div class="news-box" *ngFor="let newsItem of latestNews">
      <div class="news-box-title-date">
        <div class="news-box-title header">{{ newsItem.title }}</div>
        <div class="news-box-date">{{ newsItem.date }}</div>
      </div>
      <div class="news-box-message-button">
        <div class="news-box-message">{{ newsItem.message }}</div>
        <div class="news-box-button-wrapper">
          <button
            mat-flat-button
            color="accent"
            class="news-box-button"
            (click)="openNewsDialog(newsItem)"
          >
            Lees meer
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-loader" *ngIf="loadingPage">
  <mat-spinner></mat-spinner>
</div>
