import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit {
  loadingPage: boolean = true;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.loadingPage = false;
  }
}
