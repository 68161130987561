import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-episode',
  templateUrl: './episode.component.html',
  styleUrl: './episode.component.scss',
})
export class EpisodeComponent implements OnInit {
  loadingPage: boolean = true;

  constructor() {}

  async ngOnInit(): Promise<void> {
    this.loadingPage = false;
  }
}
